<template lang="pug">
.absolute.bottom-5.top-0.left-0.z-30.bg-white.shadow.pt-5.w-module.custom.grid(
  :class="{ 'custom-width': title === 1 }"
)
  .grid.div1
    .text-darkblue.col-start-1.col-end-2.text-xl.font-bold.whitespace-no-wrap.font-SourceSansPro {{ title === 1 ? $t("edit-terminal") : $t("add-terminal") }}
    .flex.justify-end.col-start-2.items-center
      i.el-icon-close.text-2xl.cursor-pointer.text-darkblue.font-semibold(
        @click='$emit("close")'
      )
  .flex.div2
    span.flex.flex-col.col-start-1.col-end-3(
      v-if='title === 1'
      :style="{width: '100%'}"
    )
      .mt-8.flex.col-start-1.col-end-3(
        style='justify-content: space-between; align-items: flex-end'
      )
        .flex.flex-col.mt-3(class='w-1/2')
          p.text-annotationColor.text-sm.font-SourceSansPro.font-semibold.mb-3 {{ $t("objects.imei") }}*
          .flex.justify-between(:style="{width: '430px'}")
            el-input(type='text', v-model='adminImei')
        .flex.justify-end
          skif-button(@click='saveTerminal') {{ $t("btn.save") }}
      .flex.flex-col.mt-6.col-start-1.col-end-3
        .flex.flex-col
          p.text-annotationColor.text-sm.font-SourceSansPro.font-semibold.mb-3 {{ $t("companies") }}
          el-select.mr-4(class='w-1/2', v-model='company', filterable, :style="{width: '430px'}")
            el-option(
              v-for='item in companies',
              :key='item.id',
              :value='item.id',
              :label='item.name'
            )

        .flex.flex-col.mt-6
          p.text-annotationColor.text-sm.font-SourceSansPro.font-semibold.mb-3 {{ $t("loginPage.pass") }}
          .flex.justify-between(:style="{width: '430px'}")
            el-input(
              type='password',
              v-model='password',
              auto-complete='off',
              show-password
            )
        .flex.mt-9(class='', style='align-items: center')
          span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold Определение координат по LBS
          skif-switch.ml-6(v-model='use_lbs_for_coords')
        .flex.mt-9(class='w-1/2', style='align-items: center')
          span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("blocking") }}
          skif-switch.ml-6(v-model='activity')

    span.flex.flex-col.col-start-1.col-end-3(
      v-else
    )
      .mt-8.flex.col-start-1.col-end-3(
        style='justify-content: space-between; align-items: flex-end'
      )
        .flex.flex-col
          p.text-annotationColor.text-sm.font-SourceSansPro.font-semibold.mb-3 {{ $t("companies") }}
          el-select.mr-4(v-model='companiesGroup', filterable, multiple)
            el-option(
              v-for='item in companies',
              :key='item.id',
              :value='item.id',
              :label='item.name'
            )
        .flex.justify-end
          skif-button(@click='addTerminal') {{ $t("btn.save") }}
      .flex.flex-col.mt-6.col-start-1.col-end-3
        .flex.flex-col.mt-3(class='w-1/2')
          p.text-annotationColor.text-sm.font-SourceSansPro.font-semibold.mb-3 {{ $t("objects.imei") }}*
          span.text-someGrey.text-sm.font-medium.mb-6 Добавление нескольких терминалов при переносе на новую строчку.
          .flex.justify-between
            el-input(
              type='textarea',
              maxlength='2000',
              rows='4',
              v-model='adminImei'
            )
        .flex.flex-col.mt-6
          p.text-annotationColor.text-sm.font-SourceSansPro.font-semibold.mb-3 {{ $t("loginPage.pass") }}
          .flex.justify-between
            el-input(
              class='w-1/2',
              type='password',
              v-model='password',
              auto-complete='off',
              show-password
            )
        .flex.mt-9(class='w-1/2', style='align-items: center')
          span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold Определение координат по LBS
          skif-switch.ml-6(v-model='use_lbs_for_coords')
        .flex.mt-9(class='w-1/2', style='align-items: center')
          span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("blocking") }}
          skif-switch.ml-6(v-model='activity')

  .flex.flex-col.col-start-1.col-end-3.pb-4.border-b.border-divider.div3(
    :style="{paddingBottom: '20px'}"
  )
  .div4.overflow-y-auto(v-if="title === 1" :style="{marginTop: '11px'}")
    table.mt-4.table.w-full.table-fixed.text-left.border-tw-full.table-fixed
      thead.text-left.thead-custom
        th.bg-white.top-0.sticky.z-10
          span.text-darkblue.leading-10.cursor-pointer.mr-2(
            style="font-size: 14px; color: rgb(145, 180, 231);"
          ) {{ $t('send-terminal') }}
        th.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t('status-terminal') }}
        th.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t('command-terminal') }}
        th.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t('response-terminal') }}
        th.bg-white.top-0.sticky.pl-2.text-center.cursor-pointer(
          style='width: 25px',
        )
          .svg(
            @click="isCommandForm = true"
          )
            include ../../assets/icons/addNew.svg

    .form.w-full.pb-4.bg-reallylightblue(v-if="isCommandForm")
      p.form-title.text-annotationColor.text-sm.font-SourceSansPro.font-semibold {{ $t('text-command-terminal') }}
      el-input(
        :class='["form-input"]',
        :style="{width: '408px'}",
        type='text',
        auto-complete='off',
        :maxLength="1000"
        :minLength="3"
        v-model="command"
      )
      skif-button.ml-6(type='button' @click="sendCommand") Отправить
      .svg.cursor-pointer(
        style="display: inline-block; transform: rotate(45deg)"
        @click="isCommandForm = false"
      )
        include ../../assets/icons/addNew.svg

    table.mt-4.table.w-full.table-fixed.text-left.border-tw-full.table-fixed.form-table
      thead.text-left
      tbody.text-left
        tr.cursor-pointer.hover_bg-reallylightblue(
          v-for="item in commands"
          :key="item.id"
        )
          td.sent-time
            span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ item.sent_time }}
          td.status
            span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ item.status }}
          td.command
            span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ item.command }}
          td.response
            span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ item.answer }}

  .absolute.col-start-1.col-end-3.flex.items-center.justify-center.commands-pagination.div5(v-if="title === 1")
    el-pagination(
      @prev-click='prevPage',
      @next-click='nextPage',
      @current-change='handleCurrentChange',
      layout='prev, pager, next',
      :pageSize='pageSize',
      :total='maxRows',
      :current-page='pageNumber'
    )

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { adminPanel } from '@/api'

export default {
  name: 'terminalUpdateModal',
  props: {
    title: Number,
    item: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    use_lbs_for_coords: false,
    aModule: '',
    company: '',
    companiesGroup: '',
    adminImei: '',
    activity: false,
    password: '',
    isCommandForm: false,
    command: '',
    pageNumber: 0,
    pageSize: 100,
    maxRows: 0
  }),
  computed: {
    ...mapGetters({
      companies: 'login/companies',
      commands: 'terminal/commands'
    })
  },
  methods: {
    ...mapActions('terminal', ['addCommand', 'getCommands']),

    async getTerminals() {
      await adminPanel.getTerminals(
        this.item.id,
        (response) => {
          const item = response.data
          this.companies.forEach((val) => {
            if (val.name === this.item.company_name) {
              this.company = val.id
            }
          })
          if (!this.company) {
            this.companies.push({
              id: this.item.company_id,
              name: this.item.company_name
            })
            this.company = this.item.company_id
          }
          this.adminImei = item.imei ? item.imei : item.name
          this.activity = item.is_blocked
          this.password = item.password
          this.use_lbs_for_coords = item.use_lbs_for_coords
        },
        (error) => {
          this.$showError(error.response.data.message)
        }
      )
    },

    async saveTerminal() {
      const data = {
        id: this.item.id,
        terminal: {
          imei: this.adminImei,
          company_id: this.company,
          password: this.password,
          is_blocked: this.activity,
          use_lbs_for_coords: this.use_lbs_for_coords
        }
      }
      await adminPanel.putTerminals(
        data,
        (response) => {
          this.$emit('close', response)
          this.companies.pop()
        },
        (error) => {
          this.$showError(error.response.data.message)
        }
      )
    },

    async addTerminal() {
      const companies = this.companiesGroup.map((val) => {
        return { id: val }
      })
      const data = {
        imeis: this.adminImei.split('\n'),
        companies,
        company_name: 'mush',
        password: this.password,
        is_blocked: this.activity,
        use_lbs_for_coords: this.use_lbs_for_coords
      }
      await adminPanel.addTerminals(
        data,
        (response) => {
          this.$emit('close', response.data)
        },
        (error) => {
          this.$showError(error.response.data.message)
        }
      )
    },

    async sendCommand() {
      await this.addCommand({
        company_id: this.item.company_id,
        imei: this.item.imei,
        command: this.command.trim()
      })
      this.command = ''
      this.isCommandForm = false
    },

    handleCurrentChange(val) {
      this.pageNumber = val
      this.formData.from = (this.pageNumber - 1) * this.pageSize
      this.getCommands({
        from: this.pageNumber,
        count: this.pageSize,
        conditions: [{ field: 'imei', value: this.item.imei }],
        model: 'commands'
      })
    },

    prevPage() {
      this.pageNumber--
    },

    nextPage() {
      this.pageNumber++
    }
  },
  async mounted() {
    if (this.item) {
      this.getTerminals()
      await this.getCommands({
        from: this.pageNumber,
        count: this.pageSize,
        conditions: [{ field: 'imei', value: this.item.imei }],
        model: 'commands'
      })
    }
  }
}
</script>

<style lang="stylus">
.custom
  grid-template-rows 20px 0.5fr 10px 1fr 60px
  grid-template-areas "div1 div1"\
    "div2 div2"\
    "div3 div3"\
    "div4 div4"\
    "div5 div5"
.custom-width
  width 682px
.div1 { grid-area: div1; }
.div2 { grid-area: div2; }
.div3 { grid-area: div3; }
.div4 { grid-area: div4; }
.div5 { grid-area: div5; }
.svg
  svg
    width 16px
    height 16px
    path
      fill #91B4E7
.commands-pagination
  bottom 0
  left 50%
  transform translate(-50%, -50%)

.div1, .div2, .div3
  padding-left 1.5rem
  padding-right 1rem

.div3
  margin-left 1.5rem
  margin-right 1rem

.thead-custom
  display flex
  align-items center
  padding-left 1.5rem
  padding-right 1rem
  th
    &:nth-of-type(1)
      width 170px
      span
        width inherit
        display block
    &:nth-of-type(2)
      width 97px
    &:nth-of-type(3)
      width 103px
      span
        width inherit
        display block
    &:nth-of-type(4)
      width 201px
      span
        width inherit
        display block

.form
  .svg
    margin-left 2.8rem

.form-title
  padding 19px 0 15px 24px
.form-input
  padding-left 24px
.form-table
  display block
  padding-left 24px
  padding-right 1rem
  .sent-time
    width 170px
    span
      display block
      width inherit
      word-wrap break-word
  .status
    width 91px
    span
      display block
      width inherit
      word-wrap break-word
  .command
    width 100px
    span
      display block
      width inherit
      word-wrap break-word
  .response
    width 201px
    span
      display block
      width inherit
      word-wrap break-word
.el-input.el-input--error
  input
    border 1px solid red
</style>
